import React, { useEffect, useState } from 'react';

import { ConnectionStatus } from 'Enums';

import { Main, TitleActionBar } from 'Components/utility';

import mediator from 'Utilities/mediator';

import ConnectedDetails from './ConnectedDetails';
import PendingDetails from './PendingDetails';

type Props = {
  data: { connection?: Connection };
};

const ConnectionDetails: React.FC<Props> = ({ data }) => {
  const [connection, setConnection] = useState<Connection>({
    CaregiverEmail: '',
    CaregiverFirstName: '',
    CaregiverID: '',
    CaregiverLastName: '',
    ConnectionID: '',
    PatientID: '',
    Status: 1,
  });

  useEffect(() => {
    if (!data.connection) {
      mediator.publish('router:navigate', '/connections');
      return;
    }

    setConnection(data.connection);
  }, [data.connection]);

  return (
    <Main>
      <TitleActionBar
        testID="ConnectionDetails"
        title={`${connection.CaregiverFirstName} ${connection.CaregiverLastName}`}
      />
      {connection.Status === ConnectionStatus.CONNECTED ? (
        <ConnectedDetails connection={connection} />
      ) : (
        <PendingDetails connection={connection} />
      )}
    </Main>
  );
};

export default ConnectionDetails;
