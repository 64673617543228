import React, { memo } from 'react';

import { Button as ButtonParallax, ButtonProps } from '@adc/parallax-component-library';

const Button = memo(({ testID, disabled, ...rest }: ButtonProps) => {
  return (
    <ButtonParallax
      testID={disabled ? `${testID}-disabled` : `${testID}`}
      disabled={disabled}
      {...rest}
    />
  );
});

Button.displayName = 'Button';

export { Button };
