import React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Toast } from '@adc/parallax-component-library';

import { useRouter } from 'Hooks';

import Modal from 'Components/utility/Modal/GeneralModal';

import { RootState } from 'src/reducers';

const mapState = ({ nav: { location, initErrorStatus, queryString, data } }: RootState) => {
  return {
    initErrorStatus,
    queryString,
    location,
    data,
  };
};
const connector = connect(mapState);

type Props = ConnectedProps<typeof connector>;

const App: React.FC<Props> = ({ initErrorStatus, queryString, location, data }) => {
  const { Component, props } = useRouter(location, queryString, initErrorStatus, data);

  return (
    <>
      <Component {...props} />
      <Toast parentHeight={window.innerHeight} bottomMargin={30} testID="Toast.global" />
      <Modal />
    </>
  );
};

export default connector(App);
