export const NAVIGATION = 'NAVIGATION';
export const REDIRECTION = 'REDIRECTION';
export const INITIALIZATION_ERROR = 'INITIALIZATION_ERROR';

export interface NavState {
  location: string;
  previousLocation: string;
  query: NavigationQueryString;
  queryString: string;
  redirection?: string;
  initErrorStatus?: number;
  data?: any;
}

export interface NavigationAction {
  type: typeof NAVIGATION;
  context: PageContext;
}

export interface RedirectionAction {
  type: typeof REDIRECTION;
  redirect: string;
}

export interface InitializationErrorAction {
  type: typeof INITIALIZATION_ERROR;
  initErrorStatus: number;
  queryString?: string;
}

export type NavActions = NavigationAction | RedirectionAction | InitializationErrorAction;
