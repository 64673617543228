import React, { useState } from 'react';

import { Text } from '@adc/parallax-component-library';
import { AxiosError } from 'axios';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

import { ApiStatus } from 'Enums';

import { useApiError } from 'Hooks';

import {
  Footer,
  Form,
  Input,
  Main,
  ScrollContainer,
  Spinner,
  TitleActionBar,
} from 'Components/utility';

import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';

import { addConnection } from 'Services/llu';

interface Connection {
  firstName: string;
  lastName: string;
  email: string;
}

const initialValues: Connection = {
  firstName: '',
  lastName: '',
  email: '',
};

const AddConnection = () => {
  const [loading, setLoading] = useState(false);

  const { showApiErrorModal } = useApiError();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string()
      .email(
        i18n.t<string>('Login.content.signInForm.formField.email.errors.emailAddressValidEmail')
      )
      .required('Required'),
  });

  const onSubmit = async (values: Connection, { setFieldError }: FormikHelpers<Connection>) => {
    try {
      setLoading(true);
      await addConnection(values.firstName, values.lastName, values.email);
      mediator.publish('router:navigate', '/connections');
    } catch (err) {
      const error = err as AxiosError<ApiErrorData>;

      switch (error.status) {
        case ApiStatus.BAD_REQUEST:
          setFieldError(
            'email',
            i18n.t('Connections.content.addConnectionForm.formField.email.errors.locationError')
          );
          break;
        default:
          showApiErrorModal();
      }
    } finally {
      setLoading(false);
    }
  };

  const { handleSubmit, handleChange, handleBlur, isValid, errors, touched, values } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnBlur: true,
  });

  return (
    <Main>
      <TitleActionBar
        testID="AddConnection"
        title={i18n.t('Connections.content.addConnectionForm.primaryText')}
        closeBtn
      />
      {loading && <Spinner testID="Login" />}
      <ScrollContainer marginTop={0}>
        <Form>
          <Text
            testID="AddConnection.secondaryText"
            fontSize="$bodyBase.default"
            fontWeight="$bodyBase.default"
            color="$text.100"
            marginBottom="$4"
          >
            {i18n.t<string>('Connections.content.addConnectionForm.secondaryText')}
          </Text>
          <Input
            aria-label={i18n.t<string>(
              'Connections.content.addConnectionForm.formField.firstName.label'
            )}
            placeholder={i18n.t<string>(
              'Connections.content.addConnectionForm.formField.firstName.placeholder'
            )}
            label={i18n.t<string>(
              'Connections.content.addConnectionForm.formField.firstName.label'
            )}
            testID="firstName"
            inputMode="text"
            errorMessage={errors.firstName}
            isInvalid={errors.firstName && touched.firstName ? true : false}
            value={values.firstName}
            onChangeText={handleChange('firstName')}
            onBlur={handleBlur('firstName')}
          />
          <Input
            aria-label={i18n.t<string>(
              'Connections.content.addConnectionForm.formField.lastName.label'
            )}
            placeholder={i18n.t<string>(
              'Connections.content.addConnectionForm.formField.lastName.placeholder'
            )}
            label={i18n.t<string>('Connections.content.addConnectionForm.formField.lastName.label')}
            testID="lastName"
            inputMode="text"
            errorMessage={errors.lastName}
            isInvalid={errors.lastName && touched.lastName ? true : false}
            value={values.lastName}
            onChangeText={handleChange('lastName')}
            onBlur={handleBlur('lastName')}
          />
          <Input
            aria-label={i18n.t<string>(
              'Connections.content.addConnectionForm.formField.email.label'
            )}
            placeholder={i18n.t<string>(
              'Connections.content.addConnectionForm.formField.email.placeholder'
            )}
            label={i18n.t<string>('Connections.content.addConnectionForm.formField.email.label')}
            testID="email"
            inputMode="text"
            errorMessage={errors.email}
            isInvalid={errors.email && touched.email ? true : false}
            value={values.email}
            onChangeText={handleChange('email')}
            onBlur={handleBlur('email')}
          />
          <Text
            testID="AddConnection.body"
            fontSize="$bodyBase.default"
            fontWeight="$bodyBase.default"
            color="$text.60"
            marginTop="$2"
          >
            {i18n.t<string>('Connections.content.addConnectionForm.bodyText')}
          </Text>
        </Form>
      </ScrollContainer>
      <Footer
        buttonText={i18n.t<string>(
          'Connections.content.addConnectionForm.links.inviteConnection.label'
        )}
        onButtonSubmit={handleSubmit}
        testIDButton="AddConnection.inviteConnection-btn"
        isButtonDisabled={!values.firstName || !values.lastName || !values.email || !isValid}
      />
    </Main>
  );
};

export default AddConnection;
