enum ApiStatus {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  CONFLICT = 409,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
  FORBIDDEN_HCP_ACCOUNT = 18,
  LOCKOUT = 9,
  API_ERROR = 1,
  SESSION_MANAGEMENT_ERROR = 20,
  PATIENT_EXISTS = 2,
  PASSWORD_VALIDATION = 4,
  WRONG_REGION = 5,
}

export default ApiStatus;
