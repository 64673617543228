import { useMemo } from 'react';

import ForgotPasswordPage from 'Components/auth/ForgotPassword';
import LoginPage from 'Components/auth/Login';
import AddConnection from 'Components/connection/AddConnection';
import ConnectionDetails from 'Components/connection/ConnectionDetails';
import MainConnection from 'Components/connection/MainConnection';
import InitializationErrorPage from 'Components/utility/InitializationErrorPage';

const routes: { route: string; component: React.FC<any>; props?: any }[] = [
  { route: 'login', component: LoginPage },
  { route: 'connections', component: MainConnection },
  { route: 'connection-details', component: ConnectionDetails },
  { route: 'add-connection', component: AddConnection },
  { route: 'forgot-password', component: ForgotPasswordPage },
];

const useRouter = (location: string, queryString: string, initErrorStatus?: number, data?: any) => {
  const { Component, props } = useMemo(() => {
    if (initErrorStatus) {
      return {
        Component: InitializationErrorPage,
        props: { errorStatus: initErrorStatus, navigationQueryString: queryString },
      };
    }

    const lowerCaseLocation = location.toLowerCase();

    const route = routes.find((r) => lowerCaseLocation.startsWith(`/${r.route.toLowerCase()}`));

    if (route) {
      return { Component: route.component, props: { ...route.props, data } };
    }

    return { Component: LoginPage };
  }, [data, initErrorStatus, location, queryString]);

  return { Component, props };
};

export default useRouter;
