import React from 'react';

import { HStack, StackProps } from '@adc/parallax-component-library';

type ImageProps = {
  src: string;
  alt?: string;
} & StackProps;

const Image: React.FC<ImageProps> = ({ src, testID, alt = '', ...rest }) => {
  return (
    <HStack testID={testID} {...rest}>
      <img src={src} alt={alt} />
    </HStack>
  );
};

export { Image };
