import React, { forwardRef, memo } from 'react';

import { PasswordInput, PasswordInputProps } from '@adc/parallax-component-library';
import { GetThemeValueForKey } from '@tamagui/core';

import InputErrorField from 'Components/utility/InputErrorField';

type InputPasswordProps = Omit<
  PasswordInputProps,
  'ariaLabelExtractor' | 'hideButtonAriaLabelExtractor' | 'autoComplete' | 'inputMode' | 'valid'
> & {
  isInvalid?: boolean;
  errorMessage?: string;
  isEyeIconVisible?: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  mt?: GetThemeValueForKey<'marginTop'>;
  mb?: GetThemeValueForKey<'marginBottom'>;
  autoComplete?: 'current-password' | 'new-password';
  isDisabled?: boolean;
};

const InputPassword = memo(
  ({
    isInvalid = false,
    label = '',
    errorMessage,
    placeholder = '',
    value,
    onBlur,
    onFocus,
    setFieldValue,
    mt,
    mb,
    autoComplete = 'current-password',
    isDisabled,
    onChangeText,
    testID,
    ...rest
  }: InputPasswordProps) => {
    return (
      <>
        <PasswordInput
          label={label}
          inputMode={'password'}
          valid={isInvalid ? 'no' : 'yes'}
          onBlur={onBlur}
          onFocus={onFocus}
          ariaLabelExtractor={() => ''}
          hideButtonAriaLabelExtractor={() => ''}
          autoComplete={autoComplete}
          marginTop={mt}
          marginBottom={mb}
          value={value}
          disabled={isDisabled}
          placeholder={placeholder}
          onChangeText={onChangeText}
          testID={testID}
          {...rest}
        />
        {isInvalid && errorMessage && (
          <InputErrorField errorMessage={errorMessage} testID={testID} />
        )}
      </>
    );
  }
);

InputPassword.displayName = 'InputPassword';

export { InputPassword };
