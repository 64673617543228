import React from 'react';
import Animated, { Extrapolation, interpolate, useAnimatedStyle } from 'react-native-reanimated';
import { ConnectedProps, connect } from 'react-redux';

import { HStack, Text, VStack } from '@adc/parallax-component-library';

import i18n from 'Utilities/i18n';

import { RootState } from 'src/reducers';

import { ActionBarHeader } from './ActionBarHeader';

type LogoActionBarProps = {
  testID?: string;
  title?: string;
};

const mapStateToProps = ({ scrollPage }: RootState) => {
  return {
    scrollPage,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & LogoActionBarProps;

const LogoActionBar: React.FC<Props> = ({ testID, scrollPage, title }) => {
  const logoAnimatedStyles = useAnimatedStyle(() => {
    if (!scrollPage.isActive) {
      return {
        opacity: 1,
      };
    }

    const opacity = interpolate(scrollPage.scrollValue, [0, 20], [1, 0], Extrapolation.CLAMP);

    return {
      opacity,
    };
  });

  const titleAnimatedStyles = useAnimatedStyle(() => {
    if (!scrollPage.isActive) {
      return {
        opacity: 0,
      };
    }

    const opacity = interpolate(scrollPage.scrollValue, [0, 20], [0, 1], Extrapolation.CLAMP);

    return {
      opacity,
    };
  });

  return (
    <VStack
      style={{
        top: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        zIndex: 2,
      }}
      backgroundColor="$surface.container"
    >
      <ActionBarHeader testID={testID} />
      <HStack
        padding="$4"
        justifyContent="center"
        alignItems="center"
        testID={`${testID}-libreLogo`}
      >
        <Animated.View style={logoAnimatedStyles}>
          <img src={i18n.t('Global.microcopy.common.libreLogo')} alt="" />
        </Animated.View>
        {title && (
          <Animated.View style={[{ position: 'absolute', zIndex: 1 }, titleAnimatedStyles]}>
            <Text
              testID={`${testID}.action-title`}
              color="$text.100"
              fontSize="$h4.strong"
              fontWeight="$h4.strong"
            >
              {title}
            </Text>
          </Animated.View>
        )}
      </HStack>
    </VStack>
  );
};

const ConnectedLogoActionBar = connector(LogoActionBar);

export { ConnectedLogoActionBar as LogoActionBar };
