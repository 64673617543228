import { urls } from 'Utilities/dataHelpers';
import log from 'Utilities/log';
import mediator from 'Utilities/mediator';

export const getConnections = () => {
  return new Promise<Connection[]>((resolve, reject) => {
    mediator
      .request<ApiResponse<Connection[]>>('api:invoke', {
        method: 'GET',
        url: urls.libreSharingApiUrl + 'sharing/llu/connections',
      })
      .then((result) => {
        if (result.data) {
          return resolve(result.data);
        }

        reject();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const addConnection = (firstName: string, lastName: string, email: string) => {
  return new Promise<void>((resolve, reject) => {
    mediator
      .request<ApiResponse<void>>('api:invoke', {
        method: 'POST',
        url: urls.libreSharingApiUrl + 'sharing/llu/invitations',
        data: {
          firstName,
          lastName,
          email,
        },
      })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const removeConnection = (connectionId: string) => {
  return new Promise<void>((resolve, reject) => {
    mediator
      .request<ApiResponse<void>>('api:invoke', {
        method: 'DELETE',
        url: urls.libreSharingApiUrl + 'sharing/llu/connections/' + connectionId,
      })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const removeInvitation = (connectionId: string) => {
  return new Promise<void>((resolve, reject) => {
    mediator
      .request<ApiResponse<void>>('api:invoke', {
        method: 'DELETE',
        url: urls.libreSharingApiUrl + 'sharing/llu/invitations/' + connectionId,
      })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};
