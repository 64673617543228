import React, { memo } from 'react';

import { HStack, Stack, Text } from '@adc/parallax-component-library';
import { IconValidationInvalid } from '@adc/parallax-icons';

import { RichText } from 'Components/utility';

type Props = {
  errorMessage?: string;
  testID?: string;
};

const InputErrorField: React.FC<Props> = ({ testID, errorMessage }) => {
  if (!errorMessage) {
    return null;
  }

  return (
    <HStack marginBottom="$2">
      <Stack marginRight="$2">
        <IconValidationInvalid
          color="$support.danger.surfaceOnColorAlt"
          testID={`${testID}-icon`}
        />
      </Stack>
      <RichText
        color="$support.danger.surfaceOnColorAlt"
        fontSize="$bodySmall.default"
        fontWeight="$bodySmall.default"
        testID={`${testID}-errorMessage`}
        text={errorMessage}
      />
    </HStack>
  );
};

export default memo(InputErrorField);
