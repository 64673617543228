import React from 'react';

export type Props = React.HTMLAttributes<HTMLDivElement>;

const Form = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  (props: Props, ref) => {
    const { children, style } = props;

    return (
      <div
        ref={ref}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: 16,
          ...style,
        }}
      >
        {children}
      </div>
    );
  }
);

Form.displayName = 'Form';

export { Form };
