import React, { useCallback, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { AxiosError } from 'axios';

import { ApiStatus } from 'Enums';

import { useApiError, useAuth } from 'Hooks';

import Wizard from 'Components/wizard';
import WizardMainContainer from 'Components/wizard/WizardMainContainer';

import { forgotPass } from 'Services/auth';

import { RootState } from 'src/reducers';

import ForgotPassFirstStep from './steps/ForgotPassFirstStep';
import ForgotPassSecondStep from './steps/ForgotPassSecondStep';

const mapStateToProps = ({ env: { app, pId } }: RootState) => {
  return {
    app,
    pId,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const ForgotPasswordPage: React.FC<Props> = ({ app, pId }) => {
  const [loading, setLoading] = useState(false);
  const [initialValues] = useState<{ email: string }>({
    email: '',
  });

  const { showApiErrorModal } = useApiError();

  const { showHcpError, showForgotPasswordBlockedMessage, showForgotPasswordConfirmatioMessage } =
    useAuth();

  const handleApiRequestError = useCallback(
    (error: AxiosError<ApiErrorData>) => {
      switch (error.status) {
        case ApiStatus.FORBIDDEN: {
          const code = error.response?.data.code;

          if (code === ApiStatus.FORBIDDEN_HCP_ACCOUNT) {
            showHcpError();
          }

          break;
        }

        case ApiStatus.TOO_MANY_REQUESTS:
          showForgotPasswordBlockedMessage();
          break;
        default:
          showApiErrorModal();
      }
    },
    [showApiErrorModal, showForgotPasswordBlockedMessage, showHcpError]
  );

  const handleSubmitForgotPass = useCallback(
    (email: string) => {
      return new Promise<void>((resolve, reject) => {
        setLoading(true);
        forgotPass(email, app, pId)
          .then(resolve)
          .catch((error: AxiosError<ApiErrorData>) => {
            handleApiRequestError(error);
            reject(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
    [app, handleApiRequestError, pId]
  );

  const makeSteps = useMemo(() => {
    return [
      {
        component: ForgotPassFirstStep,
        componentName: 'ForgotPassFirstStep',
      },
      {
        component: ForgotPassSecondStep,
        componentName: 'ForgotPassSecondStep',
      },
    ];
  }, []);

  return (
    <React.Fragment>
      <WizardMainContainer>
        <Wizard
          steps={makeSteps}
          initialValues={initialValues}
          handleSubmitForgotPass={handleSubmitForgotPass}
          showForgotPasswordConfirmatioMessage={showForgotPasswordConfirmatioMessage}
          loading={loading}
        />
      </WizardMainContainer>
    </React.Fragment>
  );
};

export default connector(ForgotPasswordPage);
