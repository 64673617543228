import React, { useCallback, useMemo, useState } from 'react';

import { Modal } from '@adc-polaris-component-library/component-library';

import { ApiStatus } from 'Enums';

import { Main, Spinner } from 'Components/utility';

import { INITIALIZATION_ERROR } from 'Reducers/nav/types';

import { notifyApp } from 'Utilities/appCommunicator';
import { _config } from 'Utilities/envConfig';
import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';
import { store } from 'Utilities/store';

type Props = {
  errorStatus?: number;
  navigationQueryString?: string;
};

type Error = {
  title: string;
  message: string;
  action?: () => void;
};

const InitializationErrorPage: React.FC<Props> = ({ errorStatus }) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const error: Error = useMemo(() => {
    switch (errorStatus) {
      case ApiStatus.INTERNAL_SERVER_ERROR:
        return {
          title: 'Global.modals.internetConnectionError.title',
          message: 'Global.modals.internetConnectionError.body',
        };
      case ApiStatus.UNAUTHORIZED:
        return {
          title: 'Global.modals.featureNotAvailable.title',
          message: 'Global.modals.featureNotAvailable.body',
          action: () => {
            setLoading(true);
            notifyApp('adc-webview:navigation');
            setTimeout(() => {
              setLoading(false);
            }, 5000);
          },
        };
      default:
        return {
          title: 'Global.modals.internetConnectionError.title',
          message: 'Global.modals.errorCommunicatingServer.body',
        };
    }
  }, [errorStatus]);

  const onClose = useCallback(() => {
    setIsOpen(false);

    if (error.action) {
      error.action();
      return;
    }

    setLoading(true);

    notifyApp('adc-webview:navigation');

    // Give the app some time to redirect the user back.
    setTimeout(() => {
      if (store) {
        store.dispatch({ type: INITIALIZATION_ERROR, initErrorStatus: 0 });
      }

      setLoading(false);

      if (_config.environment !== 'production') {
        mediator.publish('router:reload');
      }
    }, 5000);
  }, [error]);

  return (
    <Main>
      {loading && <Spinner />}
      <Modal isOpen={isOpen}>
        <Modal.Header testID={error.title}>{i18n.t<string>(error.title)}</Modal.Header>
        <Modal.Body>
          <Modal.BodyText testID={error.message} nativeID={error.message}>
            {i18n.t<string>(error.message)}
          </Modal.BodyText>
        </Modal.Body>
        <Modal.ButtonContainer>
          <Modal.Button testID="Global.microcopy.common.okCap" onPress={onClose}>
            {i18n.t<string>('Global.microcopy.common.okCap')}
          </Modal.Button>
        </Modal.ButtonContainer>
      </Modal>
    </Main>
  );
};

export default InitializationErrorPage;
