import React, { useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { IconQuestionmark } from '@adc/parallax-icons';
import { AxiosError } from 'axios';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

import { ApiStatus } from 'Enums';

import { useApiError, useAuth } from 'Hooks';

import {
  Banner,
  Footer,
  Form,
  Header,
  Input,
  InputPassword,
  Link,
  LogoActionBar,
  Main,
  ScrollContainer,
  Spinner,
} from 'Components/utility';

import { AUTH_SESSION } from 'Reducers/auth/types';

import { notifyApp } from 'Utilities/appCommunicator';
import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';
import { store } from 'Utilities/store';

import { signIn } from 'Services/auth';

import { RootState } from 'src/reducers';

interface MyFormValues {
  email: string;
  password: string;
}

const initialValues: MyFormValues = { email: '', password: '' };

const mapStateToProps = ({
  env: { deviceId, osType, osVersion, app, appVersion },
  nav: { redirection },
}: RootState) => {
  return {
    deviceId,
    osType,
    osVersion,
    app,
    appVersion,
    redirection,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const Login: React.FC<Props> = ({ deviceId, osType, osVersion, app, appVersion }) => {
  const [loading, setLoading] = useState(false);

  const { showApiErrorModal } = useApiError();

  const {
    handleLoginError,
    handleLockoutError,
    showHcpError,
    isLocked,
    lockoutMessage,
    lockoutSubmessage,
  } = useAuth();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        i18n.t<string>('Login.content.signInForm.formField.email.errors.emailAddressValidEmail')
      )
      .required(i18n.t<string>('Login.content.signInForm.formField.email.errors.required')),
    password: Yup.string().required(
      i18n.t<string>('Login.content.signInForm.formField.email.errors.required')
    ),
  });

  const handleApiRequestError = useCallback(
    (error: AxiosError<ApiErrorData>, resetForm: () => void) => {
      const data = error.response?.data
        ? error.response.data
        : { details: i18n.t('Global.modals.errorCommunicatingServer.body') };

      notifyApp('adc-webview:signin', { status: error.status, data });

      switch (error.status) {
        case ApiStatus.UNAUTHORIZED: {
          const code = error.response?.data.code;

          if (code === ApiStatus.FORBIDDEN_HCP_ACCOUNT) {
            showHcpError();
            return;
          }

          handleLoginError(error, resetForm);
          break;
        }

        case ApiStatus.TOO_MANY_REQUESTS: {
          handleLockoutError(error);
          resetForm();
          break;
        }

        default:
          showApiErrorModal();
      }
    },
    [handleLockoutError, handleLoginError, showApiErrorModal, showHcpError]
  );

  const signInRequest = useCallback(
    async (formValues: MyFormValues, resetForm: () => void, force?: boolean) => {
      try {
        setLoading(true);

        const { email, password } = formValues;

        const authSession = await signIn(
          email,
          password.trim(),
          osType,
          osVersion,
          app,
          appVersion,
          deviceId,
          force
        );

        store.dispatch({ type: AUTH_SESSION, authSession });

        notifyApp<AuthSession>('adc-webview:signin', authSession);

        mediator.publish('router:navigate', '/connections');
      } catch (err) {
        setLoading(false);

        const error = err as AxiosError<ApiErrorData>;

        handleApiRequestError(error, resetForm);
      }
    },
    [app, appVersion, deviceId, handleApiRequestError, osType, osVersion]
  );

  const onSubmit = useCallback(
    async (values: MyFormValues, { setSubmitting, resetForm }: FormikHelpers<MyFormValues>) => {
      signInRequest(values, resetForm).finally(() => {
        setSubmitting(false);
      });
    },
    [signInRequest]
  );

  const handleClickForgotPass = useCallback(() => {
    mediator.publish('router:navigate', '/forgot-password');
  }, []);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    isValid,
    errors,
    touched,
    values,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    initialErrors: {
      email: i18n.t<string>(
        'Login.content.signInForm.formField.email.errors.emailAddressValidEmail'
      ),
      password: i18n.t<string>('Login.content.signInForm.formField.email.errors.required'),
    },
  });

  return (
    <Main>
      <LogoActionBar testID="Login" title={i18n.t<string>('Login.title')} />
      {loading && <Spinner testID="Login" />}
      <ScrollContainer>
        <Header
          testID="Login"
          title={i18n.t<string>('Login.title')}
          subtitle={i18n.t<string>('Login.subtitle', {
            appName: i18n.t<string>('Global.appNames.libreView'),
          })}
        />
        <Banner testID="Login" message={lockoutMessage} submessage={lockoutSubmessage} />
        <Form>
          <Input
            aria-label={i18n.t<string>('Login.content.signInForm.formField.email.label')}
            placeholder={i18n.t<string>('Login.content.signInForm.formField.email.placeholder')}
            label={i18n.t<string>('Login.content.signInForm.formField.email.label')}
            errorMessage={errors.email}
            isInvalid={errors.email && touched.email ? true : false}
            value={values.email}
            testID="email"
            onChangeText={handleChange('email')}
            onBlur={handleBlur('email')}
            inputMode="email"
            disabled={isLocked}
          />
          <InputPassword
            placeholder={i18n.t<string>('Login.content.signInForm.formField.password.placeholder')}
            label={i18n.t<string>('Login.content.signInForm.formField.password.label')}
            errorMessage={errors.password}
            testID="password"
            setFieldValue={setFieldValue}
            onBlur={handleBlur('password')}
            onChangeText={handleChange('password')}
            value={values.password}
            isEyeIconVisible={!isLocked}
            isInvalid={errors.password && touched.password ? true : false}
            isDisabled={isLocked}
          />
          <Link
            style={{ marginTop: 10 }}
            testID="Login.forgotPassword"
            onPress={handleClickForgotPass}
            IconElement={IconQuestionmark}
          >
            {i18n.t<string>('Login.content.signInForm.secondaryText')}
          </Link>
        </Form>
      </ScrollContainer>
      <Footer
        buttonText={i18n.t<string>('Login.title')}
        onButtonSubmit={handleSubmit}
        testIDButton="Login.signIn"
        isButtonDisabled={isLocked || !isValid}
      />
    </Main>
  );
};

export default connector(Login);
