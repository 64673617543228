import React from 'react';

import { Divider, Stack, Text } from '@adc/parallax-component-library';

import { useConnection } from 'Hooks';

import { Link } from 'Components/utility';

import i18n from 'Utilities/i18n';
import log from 'Utilities/log';
import mediator from 'Utilities/mediator';

import { removeConnection } from 'Services/llu';

type Props = {
  connection: Connection;
};

const ConnectedDetails: React.FC<Props> = ({ connection }) => {
  const { showStopSharingModal } = useConnection();

  const onStopSharing = () => {
    showStopSharingModal(async () => {
      try {
        await removeConnection(connection.ConnectionID);
        mediator.publish('router:navigate', '/connections');
      } catch (error) {
        log.error(error);
      }
    });
  };

  return (
    <Stack margin="$4" padding="$4" backgroundColor="$surface.container" border="1.5" shadow="$-1">
      <Text
        testID="ConnectedCaregiver.title"
        color="$text.100"
        fontWeight="$h3.strong"
        fontSize="$h3.strong"
      >
        {i18n.t('Connections.content.connectedCaregiver.primaryText')}
      </Text>
      <Text
        testID="ConnectedCaregiver.bodyText"
        marginTop="$2"
        color="$text.100"
        fontWeight="$bodyBase.default"
        fontSize="$bodyBase.default"
      >
        {i18n.t('Connections.content.connectedCaregiver.secondaryText')}
      </Text>
      <Text
        testID="ConnectedCaregiver.email-label"
        marginTop="$8"
        color="$text.60"
        fontWeight="$bodySmall.default"
        fontSize="$bodySmall.default"
      >
        {i18n.t('Connections.content.connectedCaregiver.bodyText')}
      </Text>
      <Text
        testID="ConnectedCaregiver.email"
        color="$text.100"
        fontWeight="$bodyBase.medium"
        fontSize="$bodyBase.medium"
      >
        {connection.CaregiverEmail}
      </Text>
      <Divider marginVertical="$4" borderColor="$neutral.40" />
      <Link
        testID="ConnectedCaregiver.stopSharing-btn"
        onPress={onStopSharing}
        textColor="$support.danger.default"
      >
        {i18n.t<string>('Connections.content.connectedCaregiver.links.stopSharing.label')}
      </Link>
    </Stack>
  );
};

export default ConnectedDetails;
