import jwtDecode from 'jwt-decode';

import { generateUserAgentHeader, urls } from 'Utilities/dataHelpers';
import * as log from 'Utilities/log';
import mediator from 'Utilities/mediator';
import { persistAuthSession } from 'Utilities/session';

export const signIn = (
  email: string,
  password: string,
  osType: string,
  osVersion: string,
  app: string,
  appVersion: string,
  deviceId: string,
  force = false
) => {
  return new Promise<AuthSession>((resolve, reject) => {
    mediator
      .request<ApiResponse<AuthResponse>>('api:invoke', {
        method: 'POST',
        url: urls.libreSharingApiUrl + 'login',
        headers: {
          'X-User-Agent': generateUserAgentHeader(app, appVersion, osType, osVersion),
          'X-Device-ID': deviceId,
        },
        params: {
          include: 'patient',
          force,
        },
        data: {
          email,
          password,
          consents: [
            {
              id: 'touLibre',
              action: 'accept',
            },
            {
              id: 'pp',
              action: 'accept',
            },
          ],
        },
      })
      .then((result) => {
        if (result.data) {
          const decodedToken = jwtDecode<{ id: string; did: string; exp: number; iat: number }>(
            result.data.access_token
          );

          const authSession: AuthSession = {
            ...result.data,
            account_id: decodedToken.id,
            expires: decodedToken.exp,
          };

          persistAuthSession({
            access_token: authSession.access_token,
            account_id: authSession.account_id,
            expires: authSession.expires,
          });

          return resolve(authSession);
        }

        reject(result);
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const forgotPass = (email: string, app: string, pId: string) => {
  return new Promise<void>((resolve, reject) => {
    mediator
      .request<ApiResponse<void>>('api:invoke', {
        method: 'POST',
        url: urls.libreSharingApiUrl + 'password/forgot',
        headers: {
          'X-User-Agent': app,
        },
        data: {
          email,
          partnerID: pId,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const resetPass = (
  password: string,
  tokenID?: string,
  product?: string,
  version?: string
) => {
  return new Promise<void>((resolve, reject) => {
    mediator
      .request<ApiResponse<void>>('api:invoke', {
        method: 'POST',
        url: urls.libreSharingApiUrl + 'password/reset',
        headers: {
          'X-User-Agent': generateUserAgentHeader(product, version),
        },
        data: {
          password,
          tokenID,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};
