import React from 'react';

import { Divider, HStack, Stack, Text, VStack } from '@adc/parallax-component-library';
import { IconInfo, IconLinkArrow12x24 } from '@adc/parallax-icons';

import { ConnectionStatus } from 'Enums';

import { IconButton, Image, Link } from 'Components/utility';

import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';

type CardConnectionProps = {
  logoUrl: string;
  infoLink: string;
  message: string;
  testID: string;
  connections: Connection[];
  onAddConnection: () => void;
};

const CardConnection: React.FC<CardConnectionProps> = ({
  logoUrl,
  infoLink,
  message,
  testID,
  onAddConnection,
  connections,
}) => {
  return (
    <Stack margin="$4" padding="$4" backgroundColor="$surface.container" border="1.5" shadow="$-1">
      <HStack justifyContent="space-between" marginBottom="$4">
        <Image src={logoUrl} testID={`${testID}.logo`} />
        <IconButton testID={`${testID}.icon-info-btn`} onPress={() => window.open(infoLink)}>
          <IconInfo color="$interactive.tertiary.enabled.text" testID={`${testID}.icon-info`} />
        </IconButton>
      </HStack>
      {connections.length > 0 ? (
        <VStack marginTop="$4" style={{ cursor: 'pointer' }}>
          {connections.map((connection, index) => {
            return (
              <VStack
                key={connection.CaregiverEmail}
                marginBottom="$4"
                onPress={() =>
                  mediator.publish('router:navigate', {
                    url: '/connection-details',
                    data: { connection },
                  })
                }
              >
                <HStack justifyContent="space-between">
                  <Text
                    testID={`${testID}.name`}
                    fontWeight="$bodyBase.medium"
                    fontSize="$bodyBase.medium"
                    color="$text.100"
                  >
                    {`${connection.CaregiverFirstName} ${connection.CaregiverLastName}`}
                  </Text>
                  <IconLinkArrow12x24
                    testID={`${testID}.arrow-icon`}
                    color="$interactive.tertiary.enabled.text"
                  />
                </HStack>
                {connection.Status === ConnectionStatus.PENDING && (
                  <Text
                    color="$text.60"
                    fontSize="$bodySmall.default"
                    fontWeight="$bodySmall.default"
                    testID={`${testID}.connection-pending-label`}
                  >
                    {i18n.t('Connections.content.addConnection.secondaryText')}
                  </Text>
                )}
                {connections.length > 1 && !(index + 1 === connections.length) && (
                  <Divider marginTop="$4" borderColor="$neutral.40" />
                )}
              </VStack>
            );
          })}
        </VStack>
      ) : (
        <Text marginVertical="$4" testID={`${testID}.message`}>
          {message}
        </Text>
      )}
      <Divider marginBottom="$4" borderColor="$neutral.40" />
      <Link
        disabled={connections.length === 20}
        testID={`${testID}.addConnection-btn`}
        onPress={onAddConnection}
      >
        {i18n.t<string>('Connections.content.addConnection.links.addConnectionBtn.label')}
      </Link>
    </Stack>
  );
};

export default CardConnection;
