import { AxiosRequestConfig } from 'axios';
import { addMinutes, differenceInSeconds } from 'date-fns';

import { ApiStatus } from 'Enums';

import { getSessionObject, removeSessionObject, setSessionObject } from 'Utilities/storage';

import { MockApiHandler, MockApiResponse } from './api';
import { makePatient } from './seeds/patient';

interface IncorrectPasswordAttempts {
  attemptsLimit: number;
  lockoutEndDate: number;
  locked: boolean;
}

interface ForgotPasswordAttempts {
  attemptsLimit: number;
  locked: boolean;
  lockoutEndDate: number;
}

export const handleIncorrectPasswordError = (): MockApiResponse => {
  // clean session on page reload
  window.addEventListener('beforeunload', () => {
    removeSessionObject('incorrectPasswordAttempts');
    removeSessionObject('loginAttemptsPeriod');
  });

  const incorrectPasswordAttempts: IncorrectPasswordAttempts =
    getSessionObject<IncorrectPasswordAttempts>('incorrectPasswordAttempts') || {
      attemptsLimit: 3,
      lockoutEndDate: 0,
      locked: false,
    };

  if (incorrectPasswordAttempts.locked) {
    return {
      status: ApiStatus.TOO_MANY_REQUESTS,
      response: {
        headers: {
          'x-attempts-limit': '3',
          'x-attempts-reset-after': '300',
          'x-attempts-remaining': incorrectPasswordAttempts.attemptsLimit.toString(),
          'x-attempts-reset-at': incorrectPasswordAttempts.lockoutEndDate.toString(),
        },
        data: {
          details: 'Request locked',
          code: ApiStatus.LOCKOUT,
        },
      },
    };
  }

  const sessionLoginAttemptsPeriod = getSessionObject<string>('loginAttemptsPeriod');
  const loginAttemptsPeriod = sessionLoginAttemptsPeriod
    ? new Date(sessionLoginAttemptsPeriod)
    : new Date();

  setSessionObject('loginAttemptsPeriod', loginAttemptsPeriod.toISOString());

  if (differenceInSeconds(new Date(), loginAttemptsPeriod) <= 60) {
    incorrectPasswordAttempts.attemptsLimit -= 1;

    if (incorrectPasswordAttempts.attemptsLimit === 0) {
      incorrectPasswordAttempts.locked = true;

      incorrectPasswordAttempts.lockoutEndDate = addMinutes(new Date(), 5).getTime() / 1000; // add 5 minutes from current date

      setTimeout(() => {
        removeSessionObject('incorrectPasswordAttempts');
        removeSessionObject('loginAttemptsPeriod');
      }, 300000); // wait 5 minutes to unlock the user

      return {
        status: ApiStatus.UNAUTHORIZED,
        response: {
          headers: {
            'x-attempts-limit': '3',
            'x-attempts-reset-after': '300',
            'x-attempts-remaining': incorrectPasswordAttempts.attemptsLimit.toString(),
            'x-attempts-reset-at': incorrectPasswordAttempts.lockoutEndDate,
          },
          data: {
            details: 'Unauthorized',
          },
        },
      };
    }

    setSessionObject('incorrectPasswordAttempts', incorrectPasswordAttempts);

    return {
      status: ApiStatus.UNAUTHORIZED,
      response: {
        headers: {
          'x-attempts-limit': '3',
          'x-attempts-remaining': incorrectPasswordAttempts.attemptsLimit.toString(),
        },
        data: {
          details: 'Unauthorized',
        },
      },
    };
  }

  setSessionObject('incorrectPasswordAttempts', {
    attemptsRemaining: 2,
    locked: false,
    lockoutEndDate: 0,
  });

  removeSessionObject('loginAttemptsPeriod');

  return {
    status: ApiStatus.UNAUTHORIZED,
    response: {
      headers: {
        'x-attempts-limit': '3',
        'x-attempts-remaining': '2',
      },
      data: {
        details: 'Unauthorized',
      },
    },
  };
};

const handleLogin = (request: AxiosRequestConfig<AuthRequest>): MockApiResponse => {
  if (!request.data) {
    return {
      status: ApiStatus.BAD_REQUEST,
      message: 'Bad Request',
    };
  }

  const { email, password } = request.data;

  if (email === 'hcpaccount@abbott.com') {
    return {
      status: ApiStatus.UNAUTHORIZED,
      response: {
        data: {
          code: ApiStatus.FORBIDDEN_HCP_ACCOUNT,
        },
      },
    };
  }

  if (email === 'duplicatedsession@abbott.com' && !request.params.force) {
    return {
      status: ApiStatus.UNAUTHORIZED,
      response: {
        data: {
          code: ApiStatus.SESSION_MANAGEMENT_ERROR,
        },
      },
    };
  }

  if (password === 'incorrect') {
    return handleIncorrectPasswordError();
  }

  return {
    status: ApiStatus.OK,
    data: {
      access_token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImI2OWM4OTNjLTk1ODctNGI1NC05NGVlLTdhNWVmZmM0MDA3OSIsImRpZCI6IjU3YzQ2NDEyLWE5MzEtNGY0MC05NDc1LWRkYzE3NWMxYTI2OCIsImV4cCI6MTY5MjY3MDM0NSwiaWF0IjoxNjc3MTE4MzQ1fQ.eeZOyGCk9mdE3g19Cy7T7nLgKhw5ioNtNA05OhI8Ujg',
      token_type: 'Bearer',
      include: {
        patient: {
          ...makePatient(),
        },
      },
    },
  };
};

const handleForgotPassword = (
  request: AxiosRequestConfig<ForgotPasswordRequest>
): MockApiResponse => {
  if (!request.data?.email) {
    return {
      status: ApiStatus.BAD_REQUEST,
      message: 'Bad Request',
    };
  }

  // Handle too many requests
  const forgotPasswordAttempts: ForgotPasswordAttempts = getSessionObject<ForgotPasswordAttempts>(
    'forgotPasswordAttempts'
  ) || {
    attemptsLimit: 4,
    locked: false,
    lockoutEndDate: 0,
  };

  if (forgotPasswordAttempts.locked) {
    return {
      status: ApiStatus.TOO_MANY_REQUESTS,
      response: {
        headers: {
          'x-attempts-limit': '4',
          'x-attempts-reset-after': '300',
          'x-attempts-remaining': forgotPasswordAttempts.attemptsLimit.toString(),
          'x-attempts-reset-at': forgotPasswordAttempts.lockoutEndDate.toString(),
        },
        data: {
          details: 'Request locked',
          code: ApiStatus.LOCKOUT,
        },
      },
    };
  }

  forgotPasswordAttempts.attemptsLimit -= 1;

  if (forgotPasswordAttempts.attemptsLimit === 0) {
    forgotPasswordAttempts.locked = true;

    forgotPasswordAttempts.lockoutEndDate = addMinutes(new Date(), 5).getTime() / 1000; // add 5 minutes from current date

    setTimeout(() => {
      setSessionObject('forgotPasswordAttempts', {
        requests: 0,
        locked: false,
        lockoutEndDate: 0,
      });
      removeSessionObject('forgotPasswordAttempts');
    }, 300000); // wait 5 minutes to unlock the forgot password

    return {
      status: ApiStatus.TOO_MANY_REQUESTS,
      response: {
        headers: {
          'x-attempts-limit': '4',
          'x-attempts-reset-after': '300',
          'x-attempts-remaining': forgotPasswordAttempts.attemptsLimit.toString(),
          'x-attempts-reset-at': forgotPasswordAttempts.lockoutEndDate.toString(),
        },
        data: {
          details: 'Request locked',
          code: ApiStatus.LOCKOUT,
        },
      },
    };
  }

  setSessionObject('forgotPasswordAttempts', forgotPasswordAttempts);

  return {
    status: ApiStatus.OK,
  };
};

export const authHandlers: MockApiHandler[] = [
  {
    match: /login$/,
    handle: handleLogin,
    method: 'POST',
    name: 'login',
  },
  {
    match: /password\/forgot$/,
    handle: handleForgotPassword,
    method: 'POST',
    name: 'forgotPassword',
  },
];
