import React, { MutableRefObject, useEffect, useRef } from 'react';

import { StackProps, VStack } from '@adc/parallax-component-library';
import { GetThemeValueForKey } from '@tamagui/core';

import { MAIN_SIZE } from 'Reducers/scrollPage/types';

import { store } from 'Utilities/store';

type MainProps = StackProps & {
  px?: GetThemeValueForKey<'paddingHorizontal'>;
};

const Main = ({ px, ...rest }: MainProps) => {
  const mainRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (store && mainRef.current) {
      store.dispatch({ type: MAIN_SIZE, mainSize: mainRef.current.clientHeight });
    }
  }, [mainRef]);

  return (
    <VStack
      ref={mainRef}
      paddingHorizontal={px}
      background="$surface.background"
      style={[{ minHeight: window.innerHeight, height: '100vh' }]}
      {...rest}
    />
  );
};

export { Main };
