import React from 'react';
import { useCallback } from 'react';

import { Divider, Stack, Text } from '@adc/parallax-component-library';

import { Link } from 'Components/utility';

import { CLOSE_MODAL, SHOW_MODAL } from 'Reducers/modal/types';

import i18n from 'Utilities/i18n';
import { store } from 'Utilities/store';

const useConnection = () => {
  const showRemoveInvitationModal = useCallback((onRemoveClick: () => void) => {
    store.dispatch({
      type: SHOW_MODAL,
      modalProps: {
        body: (
          <Stack>
            <Text
              color="$text.100"
              fontWeight="$bodyBase.strong"
              fontSize="$bodyBase.strong"
              testID="RemoveConnectionModal.title"
              marginBottom="$4"
            >
              {i18n.t<string>('Global.modals.removeConnection.title')}
            </Text>
            <Text
              color="$text.100"
              fontWeight="$bodyBase.default"
              fontSize="$bodyBase.default"
              testID="RemoveConnectionModal.body"
            >
              {i18n.t<string>('Global.modals.removeConnection.body')}
            </Text>
          </Stack>
        ),
        footer: (
          <Stack width="100%">
            <Link
              testID="RemoveConnection.remove-btn"
              onPress={() => {
                store.dispatch({
                  type: CLOSE_MODAL,
                });
                onRemoveClick();
              }}
              textColor="$support.danger.default"
              fontSize="$bodyBase.medium"
              fontWeight="$bodyBase.medium"
              padding="$2"
            >
              {i18n.t<string>('Global.microcopy.common.removeCap')}
            </Link>
            <Divider borderColor="$neutral.40" />
            <Link
              testID="RemoveConnection.cancel-btn"
              fontSize="$bodyBase.default"
              fontWeight="$bodyBase.default"
              padding="$2"
              onPress={() =>
                store.dispatch({
                  type: CLOSE_MODAL,
                })
              }
            >
              {i18n.t<string>('Global.microcopy.common.cancel')}
            </Link>
          </Stack>
        ),
      },
    });
  }, []);

  const showStopSharingModal = useCallback((onRemoveClick: () => void) => {
    store.dispatch({
      type: SHOW_MODAL,
      modalProps: {
        body: (
          <Stack>
            <Text
              color="$text.100"
              fontWeight="$bodyBase.strong"
              fontSize="$bodyBase.strong"
              testID="StopSharingModal.title"
              marginBottom="$4"
            >
              {i18n.t<string>('Global.modals.stopSharing.title')}
            </Text>
            <Text
              color="$text.100"
              fontWeight="$bodyBase.default"
              fontSize="$bodyBase.default"
              testID="StopSharingModal.body"
            >
              {i18n.t<string>('Global.modals.stopSharing.body')}
            </Text>
          </Stack>
        ),
        footer: (
          <Stack width="100%">
            <Link
              testID="StopSharingModal.stop-btn"
              onPress={() => {
                store.dispatch({
                  type: CLOSE_MODAL,
                });
                onRemoveClick();
              }}
              textColor="$support.danger.default"
              fontSize="$bodyBase.medium"
              fontWeight="$bodyBase.medium"
              padding="$2"
            >
              {i18n.t<string>('Global.modals.stopSharing.title')}
            </Link>
            <Divider borderColor="$neutral.40" />
            <Link
              testID="StopSharingModal.cancel-btn"
              fontSize="$bodyBase.default"
              fontWeight="$bodyBase.default"
              padding="$2"
              onPress={() =>
                store.dispatch({
                  type: CLOSE_MODAL,
                })
              }
            >
              {i18n.t<string>('Global.microcopy.common.cancel')}
            </Link>
          </Stack>
        ),
      },
    });
  }, []);

  return { showRemoveInvitationModal, showStopSharingModal };
};

export default useConnection;
