import { AxiosRequestConfig } from 'axios';
import faker from 'faker';

import { ApiStatus } from 'Enums';

import log from 'Utilities/log';
import { getSessionObject, setSessionObject } from 'Utilities/storage';

import { MockApiHandler, MockApiResponse } from './api';

const _getIdFromUrl = (request: any) => {
  if (!request.url) {
    log.panic('the request must have a url property');
  }

  const splitUrl = request.url.split('/');

  if (splitUrl.length <= 3) {
    log.panic('the request url is supposed to include the practice id');
  }

  return splitUrl[3];
};

const handleGetConnections = (): MockApiResponse => {
  return {
    status: ApiStatus.OK,
    data: getSessionObject('connections') || [],
  };
};

const handleAddConnection = (
  request: AxiosRequestConfig<AddConnectionRequest>
): MockApiResponse => {
  if (!request.data) {
    return {
      status: ApiStatus.BAD_REQUEST,
      message: 'Bad Request',
    };
  }

  const { firstName, lastName, email } = request.data;

  if (email === 'wrongregion@abbott.com') {
    return {
      status: ApiStatus.BAD_REQUEST,
      response: {
        data: {
          code: ApiStatus.WRONG_REGION,
        },
      },
    };
  }

  const newInvite = {
    CaregiverFirstName: firstName,
    CaregiverLastName: lastName,
    CaregiverEmail: email,
    CaregiverID: 1,
    PatientID: 1,
    ConnectionID: faker.random.uuid(),
    Status: 1,
  };

  setSessionObject('connections', [
    ...(getSessionObject<Connection[]>('connections') || []),
    newInvite,
  ]);

  return {
    status: ApiStatus.OK,
  };
};

const handleRemoveConnection = (request: AxiosRequestConfig): MockApiResponse => {
  const connections: Connection[] = getSessionObject('connections') || [];
  const connectionId = _getIdFromUrl(request);

  setSessionObject(
    'connections',
    connections.filter((c) => c.ConnectionID !== connectionId)
  );

  return {
    status: ApiStatus.OK,
  };
};

const handleRemoveInvitation = (request: AxiosRequestConfig): MockApiResponse => {
  const connections: Connection[] = getSessionObject('connections') || [];
  const connectionId = _getIdFromUrl(request);

  setSessionObject(
    'connections',
    connections.filter((c) => c.ConnectionID !== connectionId)
  );

  return {
    status: ApiStatus.OK,
  };
};

export const lluHandlers: MockApiHandler[] = [
  {
    handle: handleAddConnection,
    match: /^sharing\/llu\/invitations$/,
    method: 'POST',
    name: 'addConnection',
  },
  {
    handle: handleRemoveInvitation,
    match: /^sharing\/llu\/invitations/,
    method: 'DELETE',
    name: 'removeInvitation',
  },
  {
    match: /^sharing\/llu\/connections$/,
    handle: handleGetConnections,
    method: 'GET',
    name: 'connections',
  },
  {
    match: /^sharing\/llu\/connections/,
    handle: handleRemoveConnection,
    method: 'DELETE',
    name: 'removeConnection',
  },
];
