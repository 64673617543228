import log from 'Utilities/log';

import { setSessionObject } from './storage';

// TODO: change how to add the connections
const setConnections = (
  connections: {
    CaregiverFirstName: string;
    CaregiverLastName: string;
    CaregiverEmail: string;
    CaregiverID: string;
    PatientID: string;
    ConnectionID: string;
    Status: number;
  }[]
) => {
  setSessionObject('connections', connections);
  log.info('Success! Refresh your page.');
};

declare global {
  interface Window {
    testHelper: {
      setConnections: typeof setConnections;
    };
  }
}

window.testHelper = {
  setConnections,
};
