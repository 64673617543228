import React from 'react';

import { StackProps, VStack } from '@adc/parallax-component-library';

export type IconButtonProps = StackProps & {
  onPress?: () => void;
  disabled?: boolean;
  testID?: string;
};

const IconButton = ({ children, onPress, disabled, testID, ...rest }: IconButtonProps) => {
  return (
    <VStack
      testID={testID}
      onPress={onPress}
      disabled={disabled}
      style={{ cursor: 'pointer' }}
      {...rest}
    >
      {children}
    </VStack>
  );
};

export { IconButton };
