import { useMemo } from 'react';

import { store } from 'Utilities/store';

const usePartner = () => {
  const partner = useMemo(() => {
    const partners = store.getState().config.partners;
    const pId = store.getState().env.pId;

    if (partners) {
      const partner = partners[pId];

      if (partner) {
        return partner;
      }
    }

    return { displayName: '', website: '', type: '' };
  }, []);

  return { partner };
};

export default usePartner;
